import { render, staticRenderFns } from "./index.vue?vue&type=template&id=d00d224a&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "../../../style/font/font.css?vue&type=style&index=0&id=d00d224a&prod&scoped=true&lang=css&external"
import style1 from "./index.vue?vue&type=style&index=1&id=d00d224a&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d00d224a",
  null
  
)

export default component.exports